.parentdiv{
    transform: rotate(7deg);
}
.container
{
    height: auto;
    width: 100%;
    overflow: hidden;
}
.parentdiv{
    transform: rotate(10deg);
}
@media only screen and (min-width: 1100px) 
{
    .container
    {
        height: auto;
        width: 30%;
    }
    .rowdiv
    {
        width:100%
    }
    
}
@media only screen  and (max-width: 1024px) and (min-width: 1020px) 
{
    .container
    {
        height: auto;
        width: 30%;
    }
    .rowdiv
    {
        width:100%;
        /* margin-left: 100px; */
    }
    .content
    {
        width: 100%;
        /* margin-left: 10%; */
    }
    
}
@media only screen and (max-width: 1000px) and (min-width: 600px){
    .container
    {
        height: auto;
        width: 100%;
    }
    .rowdiv
    {
        width:100%;
        /* margin-left: 100px; */
    }
    .content
    {
        width: 100%;
        /* margin-left: 10%; */
    }
}
@media only screen and (max-width: 600px) and (min-width: 400px){
    .container
    {
        height: auto;
        width: 100%;
    }
    .rowdiv
    {
        width:100%;
        /* margin-left: 50px; */
    }
}
.box {
    height: 100px; 
    width: 100px;
    display: flex;
    align-items:end;
    justify-content: center;
    border-radius: 30px;
    /* color: white; */
  }

