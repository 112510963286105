
.parentdiv{
    transform: rotate(10deg);
}
@media only screen and (min-width: 1100px) 
{
    .container
    {
        height: auto;
        width: 30%;
    }
}
@media only screen and (max-width: 1000px) and (min-width: 300px){
    .container
    {
        height: auto;
        width: 100%;
    }
}
.calendercontainer
{
    align-items: center;
    width: 100%;
}
.react-calendar__navigation {
    display: flex;

    .react-calendar__navigation__label {
      font-weight: bold;
      color:rgba(26, 54, 155, 1);
    }

    .react-calendar__navigation__arrow {
      flex-grow: 0.333;
    } 
}

.react-calendar__month-view__weekdays {
    text-align: center;
    color: rgb(108, 105, 105);
  }
 
button {
    background-color: #3f3f3f00;
    border: 0;
    border-radius: 50px;
    color: rgba(26, 54, 155, 1);
    padding: 5px 0;

    &:hover {
      background-color: #2c2c2c41;
    }

    &:active {
      background-color: #111010;
    }
    
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    visibility: hidden;
  }
  .selected-date {
    background-color: rgba(24, 31, 222, 0.5); /* Example background color for selected date */
    color: white; /* Example text color for selected date */
}