.container
{
    height: auto;
    width: 100%;
}
.parentdiv{
    transform: rotate(10deg);
}
@media only screen and (min-width: 1100px) 
{
    .container
    {
        height: auto;
        width: 30%;
    }
    
}
@media only screen and (max-width: 1000px) and (min-width: 300px){
    .container
    {
        height: auto;
        width: 100%;
    }
}
.details-container {
    width: 100%;
}

.details-container p {
    margin: 10px 0;
    font-size: 16px;
    display: flex;
    align-items: center; /* Align items vertically */
}

.details-container p strong {
    margin-right: 10px; /* Add spacing between label and value */
    min-width: 120px; /* Set minimum width for labels to ensure consistent alignment */
    display: inline-block;
    font-weight: bold;
}
