.firstdiv{
    overflow-y: hidden;
}
.firstdiv{
    background-image: url('https://i.postimg.cc/nrZbcXD0/fantasy-computer-workplace-illustration.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height:100vh;
    width:100%;
    Object-fit: cover;
}

@media only screen and (min-width: 1200px) 
{
    .secondiv{
        top: 15%;
        right: 40%;
    }
}
@media only screen and (max-width: 1100px) and (min-width: 912px) 
{
    .secondiv{
        top: 30%;
        right: 35%;
    }
}
@media only screen and (max-width: 900px) and (min-width: 768px)
{
    .secondiv{
        top: 20%;
        right: 35%;
    }
} 
@media only screen and (max-width: 760px) and (min-width: 400px)
{
    .secondiv{
        top: 20%;
        right: 20%;
    }
} 
@media only screen and (max-width: 400px) and (min-width: 200px)
{
    .secondiv{
        top: 10%;
        right: 20%;
    }
} 