.container
{
    height: auto;
    width: 100%;
}
.parentdiv{
    transform: rotate(10deg);
}
@media only screen and (min-width: 1100px) 
{
    .container
    {
        height: auto;
        width: 30%;
    }
}
@media only screen and (max-width: 1000px) and (min-width: 300px){
    .container
    {
        height: auto;
        width: 100%;
    }
}
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .popup p {
    margin-bottom: 10px;
  }
  
  .popup button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .popup button:hover {
    background-color: #0056b3;
  }